import { Link } from "react-router-dom";
import login from "../static/image/login.webp"
const Login=()=>{

return(
<section className="w-w100 h-h100 px-6 md:px-0 flex justify-center items-center bg-gradient-to-tr from-pmdark  to-sdgrad">

    <section className="md:h-p90 md:w-p60 w-full bg-white  md:grid md:grid-cols-7 rounded-lg drop-shadow-lg	 ">
<section className="md:col-span-4 hidden md:flex flex-col gap-y-20 justify-start py-16 items-center">
    <h1 className="text-3xl w-3/5  text-center font-roboto font-bold">Welcome to Technotiate Support </h1>
<img src={login} ></img>
</section>
<section className="md:col-span-3 w-full   flex flex-col box-border	 justify-between   px-6 md:px-6 gap-y-20 py-16">
    <h1 className="text-center  font-bold font-roboto text-2xl">Member Login</h1>
    <section className="w-full flex flex-col py-6">
    <form className="flex flex-col pb-4 items-center gap-y-8  w-full md:px-2">
        <section className="flex flex-col gap-y-4 w-full">
        <input className="py-3 px-4 bg-gray-200 rounded-3xl w-full " type="text" placeholder="Username"></input>
        <input className="py-3 px-4 bg-gray-200 rounded-3xl w-full " type="password" placeholder="Password"></input>
        </section>
        <input type="submit" value="LOGIN" className="py-3 w-full px-14 text-white bg-pm rounded-3xl"></input>
    </form>
    <section className="flex justify-center gap-x-2">
        <p className="text-gray-500 pb-2 text-sm underline-offset-1	 text-center">Forget </p>
    <Link to="/recover">

<p className="text-gray-600 pb-2 text-sm font-semibold underline-offset-1	 text-center">Username / Password ?</p>

</Link>
    </section>
    
    </section>
    
    <Link to="/">
    <p className="text-gray-400 pb-2 underline-offset-1	 text-center">Go to homepage</p>

    </Link>
</section>
    </section>
</section>

)

}

export default Login;