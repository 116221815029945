// import React from "react";
import React from "react";

import ReactDOM from "react-dom";
import Forget from "./component/forget";
import "./style.css";
import Home from "./component/index.js";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Helpdesk from "./component/helpdesk/index"
 import Login from "./component/login"
 import ResetPwd from "./component/reset"
ReactDOM.render(
  <Router basename="/test">
	<Route exact path='/'>
    <Home/>

	</Route>
  <Route exact path='/helpdesk'>
    {/* <Helpdesk/> */}
    <Login/>
  </Route>
  <Route exact path='/recover'>
    <Forget/>
  </Route>
  <Route exact path='/recover/code'>
    <ResetPwd/>
  </Route>
  </Router>,
      

  document.getElementById("root"),
);
