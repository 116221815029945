import React, { useEffect, useState } from "react";
// import React, {  } from "react";
import apiUrl from "../config"
import slider from "../static/image/slider/bnr-hero-855x504-market-data-center.jpg";
import about from "../static/image/about/about.jpg";
import client from "../static/image/coverimg.svg"
import customers from "../static/image/customer-service.png";
import { Link } from "react-router-dom/cjs/react-router-dom.min";


const Landing = () => {
  //   const [scrollstat, changescroll] = useState(true);
//   componentDidMount: function() {
//     window.addEventListener('scroll', myFunc);
// }
  // window.onscroll=function(){myFunc()};
  
useEffect(()=>{
  let navv=document.getElementById("buttomnav");
  let sticky=navv.offsetTop;
  const navlogo=document.getElementById("navlogo");
  const navpop=document.getElementById("navpop");
  const scroll=document.getElementById("scroll");
  function myFunc(){

    if (window.pageYOffset >= sticky) {
      navpop.classList.remove("flex")
      navpop.classList.add("hidden")
      scroll.classList.add('hidden')   
      navv.classList.add("fixed")
      navv.classList.add("text-sm")
      navlogo.classList.remove("w-60")
      navlogo.classList.add("w-40")
      // navv.classList.remove("py-4")
      navv.classList.remove("py-4")
      navv.classList.add("py-2")
    } else {
      navv.classList.remove("fixed");
      scroll.classList.remove('hidden');   

      navv.classList.remove("text-sm")
      navv.classList.remove("md:py-2")
      navlogo.classList.remove("w-40")
      navlogo.classList.remove("py-2")
      navlogo.classList.add("w-60")
      navv.classList.add("py-4")
    }
  }
  window.addEventListener('scroll',myFunc)
  return _ => {
    window.removeEventListener('scroll',myFunc)
  }
},[])

  return (
    <section className="">
      
      <Scroll />
      <Supportlogo/>
      <Slider />
      {/* <WhoWeAre /> */}

      <About />
      <Service />

      <Client />

      <Contact />

      {/* <Subscribe /> */}
    </section>
  );
};

const Scroll = () => {
  // const refContainer = useRef();

  // useEffect(() => {
  //   const div = refContainer.current;
  //   div.addEventListener("scroll", handleScroll);
  // }, [handleScroll]);

  return (
    <div id="scroll" className="fixed transition-all duration-700 flex flex-row  transform -rotate-90   top-85per text-white -left-12 md:left-0 z-40 ">
      <section className="animate-BounceX flex flex-row justify-center gap-x-4 items-center">
        <section>
          <svg
            version="1.1"
            x="0px"
            y="0px"
            className="h-4"
            viewBox="0 0 180.52 118.57"
          >
            <defs></defs>
            <path
              className="text-pm fill-current"
              d="M139.02,59.46c13.79,15.32,27.26,30.28,40.73,45.26c1.56,1.73,3.27,3.37,4.6,5.26c2.6,3.72,0.67,8.24-3.87,8.35
	c-10.99,0.27-21.99,0.2-32.98-0.06c-1.68-0.04-3.68-1.51-4.92-2.87C127.13,98.4,111.8,81.27,96.38,64.23
	c-3.17-3.5-3.05-6.44,0.17-9.97c15.48-16.99,30.72-34.18,46.26-51.11c1.55-1.69,4.51-2.81,6.86-2.89c9.49-0.32,19-0.36,28.48,0.03
	c2.37,0.1,5.72,1.66,6.65,3.53c0.78,1.56-0.69,5.1-2.22,6.84c-13.77,15.59-27.78,30.98-41.71,46.43
	C140.32,57.68,139.87,58.36,139.02,59.46z"
            />
            <path
              className="text-pmlight fill-current"
              d="M44.95,59.4c12.08,13.43,23.87,26.55,35.67,39.66c2.9,3.22,6.07,6.24,8.55,9.76c1.24,1.75,2.09,4.75,1.43,6.57
	c-0.55,1.5-3.56,2.9-5.51,2.96c-9.99,0.29-19.99,0.31-29.98,0c-2.2-0.07-4.94-1.28-6.42-2.9C33.04,98.39,17.61,81.14,2.1,63.96
	c-3.04-3.36-2.66-6.28,0.35-9.6C17.99,37.21,33.34,19.89,48.97,2.82c1.44-1.58,4.31-2.51,6.55-2.58C64.83-0.04,74.17,0.15,83.5,0.1
	c3.1-0.02,6.73-0.12,7.31,3.5c0.36,2.29-0.91,5.5-2.53,7.34C74.63,26.43,60.72,41.7,46.9,57.03C46.36,57.64,45.86,58.29,44.95,59.4z
	"
            />
          </svg>
        </section>
        <p className="tracking-widest mix-blend-difference font-bold">
          SCROLL DOWN
        </p>
      </section>
    </div>
  );
};

const Supportlogo = () => {
  // const refContainer = useRef();

  // useEffect(() => {
  //   const div = refContainer.current;
  //   div.addEventListener("scroll", handleScroll);
  // }, [handleScroll]);
const handleOnclick=()=>{
  window.location.href="/support"
}
  return (
    <Link to="/helpdesk">
    <div  className="fixed bg-sd h-16 w-16 shadow-lg cursor-pointer transition-all rounded-full duration-700 flex flex-row items-center justify-center  transform  top-90per text-white right-6 md:right-6 z-40 ">
      {/* <section className=" flex flex-row justify-center gap-x-4 items-center"> */}
        {/* <section> */} 
          <img className="h-10 w-10" alt="Technotaite SOlution Support" src={customers}></img>
        {/* </section> */}
       
      {/* </section> */}
    </div>
    </Link>
  );
};
const Slider = () => {
  const [data, dataSet] = useState([
    { title: "loading", description: "loading", backgroundimage: slider },
  ]);
  const [feature, featureSet] = useState([
    { title: "loading", description: "loading" },
    { title: "loading", description: "loading" },
    { title: "loading", description: "loading" },
  ]);
  useEffect(() => {
    // fetch(apiUrl+"/api/sliders/")
    //   .then((response) => response.json())
    //   .then((data) => console.log(data));
    async function fetchMyApi() {
      let response = await fetch(apiUrl+"/api/sliders/", {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      });
      response = await response.json();
      
      dataSet(response);
    }
    async function fetchMyApi2() {
      let response = await fetch(apiUrl+"/api/feature/", {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      });
      response = await response.json();
      
      featureSet(response);
    }
    fetchMyApi();
    fetchMyApi2();
  }, []);
  return (
    <section className="relative">
      <section id="slider" className="h-h80  relative">
        <img
          src={data[0].backgroundimage}
          className="h-full w-full object-center  absolute top-0 left-0 z-0 object-cover"
          alt=""
        />
        <section className=" px-4 md:px-32  relative z-10 bg-black bg-opacity-20 text-base md:text-lg flex flex-col justify-center items-start gap-y-4 md:gap-y-6 text-white w-full h-full">
          <h1 className="text-2xl md:text-4xl font-semibold	">
            {data[0].title}
          </h1>
          <p className="w-4/5 md:w-3/5">{data[0].description}</p>
          <button className="bg-pm text-white px-4 py-1 rounded-md">
            {data[0].linkName}
          </button>
        </section>
        {/* <p className=" relative z-10"></p> */}
      </section>
      <section className="absolute h-24 flex w-full items-center justify-center z-20 -bottom-12">
        <section className="flex bg-white md:gap-x-6 lg:gap-x-12 px-4 md:px-8 h-full items-center py-2 md:py-4 rounded-md">
          <div className="flex items-center gap-x-2 lg:gap-x-4">
            <svg
              width="50"
              height="50"
              viewBox="0 0 50 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="25" cy="25" r="25" fill="#CC7829" />
              <g clipPath="url(#clip0_3:117)">
                <path
                  d="M14.074 29.2993L10.7459 32.6274C10.6899 32.6808 10.7109 32.7772 10.7889 32.8004L14.7971 34.018C15.3658 34.191 15.8089 34.6341 15.9818 35.2026L17.1994 39.2109C17.2225 39.2889 17.319 39.3098 17.3724 39.2539L21.8254 34.8009C18.5307 33.9824 15.8106 31.9284 14.074 29.2993Z"
                  fill="white"
                />
                <path
                  d="M39.2542 32.6274L35.9261 29.2993C34.2018 31.9096 31.4902 33.9772 28.1746 34.8009L32.6276 39.2539C32.6809 39.3099 32.7775 39.289 32.8006 39.211L34.0182 35.2027C34.1912 34.6341 34.6342 34.191 35.2029 34.018L39.2112 32.8005C39.2894 32.7772 39.31 32.6806 39.2542 32.6274Z"
                  fill="white"
                />
                <path
                  d="M36.3987 22.1131C36.3987 15.8281 31.2856 10.7144 25 10.7144C18.7145 10.7144 13.6013 15.8281 13.6013 22.1131C13.6013 27.7959 17.7854 32.5323 23.2484 33.3779C30.1684 34.4544 36.3987 29.0681 36.3987 22.1131ZM16.1146 24.891C14.2348 18.8809 18.7601 12.8053 25 12.8053C31.9706 12.8053 36.5779 20.2969 33.18 26.5533C29.2574 33.7492 18.5835 32.7439 16.1146 24.891Z"
                  fill="white"
                />
                <path
                  d="M25 14.4794C20.7909 14.4794 17.3657 17.904 17.3657 22.1131C17.3657 28.3058 24.3867 31.9171 29.4145 28.3378C30.4918 27.5732 31.369 26.5279 31.9334 25.3067C34.2789 20.2572 30.5399 14.4794 25 14.4794ZM28.8531 21.0423C28.7578 21.1131 24.1198 24.5554 24.0932 24.5752C23.7582 24.8248 23.2817 24.7914 22.9838 24.4759C20.8534 22.2054 21.0238 22.3867 20.9727 22.3331C20.6147 21.9519 20.6895 21.3353 21.1373 21.0519C21.1379 21.0513 21.1385 21.0507 21.139 21.0507C21.4716 20.842 21.9152 20.8906 22.1937 21.1875C22.6442 21.6676 22.341 21.3444 23.6942 22.7862C23.8401 22.6774 25.5565 21.4041 27.9179 19.6517C28.289 19.3761 28.8135 19.4536 29.0886 19.8247C29.3407 20.1631 29.3398 20.6817 28.8531 21.0423Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_3:117">
                  <rect
                    width="28.5714"
                    height="28.5714"
                    fill="white"
                    transform="translate(10.7143 10.7143)"
                  />
                </clipPath>
              </defs>
            </svg>

            <div>
              <h1 className="font-bold text-sm md:text-base lg:text-xl">
                {feature[0].title}
              </h1>
              <p className=" text-xs md:text-sm text-gray-600">
              {feature[0].description}

              </p>
            </div>
          </div>
          <div className="flex items-center gap-x-2 lg:gap-x-4">
            <svg
              width="50"
              height="50"
              viewBox="0 0 50 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="25" cy="25" r="25" fill="#CC7829" />
              <g clipPath="url(#clip0_48:2)">
                <path
                  d="M18.3036 22.433C16.9189 22.433 15.7924 23.5595 15.7924 24.9442V31.7522C15.7924 33.1369 16.9189 34.2634 18.3036 34.2634C19.6883 34.2634 20.8147 33.1369 20.8147 31.7522V24.9442C20.8147 23.5595 19.6883 22.433 18.3036 22.433Z"
                  fill="white"
                />
                <path
                  d="M14.1183 24.9442C14.1183 24.7027 14.1502 24.4694 14.1896 24.2391C13.1808 24.5652 12.4442 25.5022 12.4442 26.6183V30.0781C12.4442 31.1941 13.1808 32.1312 14.1896 32.4572C14.1502 32.2269 14.1183 31.9937 14.1183 31.7522V24.9442Z"
                  fill="white"
                />
                <path
                  d="M31.6964 22.433C30.3117 22.433 29.1853 23.5595 29.1853 24.9442V31.7522C29.1853 33.1369 30.3117 34.2634 31.6964 34.2634C31.9481 34.2634 32.1865 34.2149 32.4159 34.1457C32.1042 35.1786 31.1554 35.9375 30.0223 35.9375H27.357C27.0103 34.9653 26.0899 34.2634 25 34.2634C23.6153 34.2634 22.4888 35.3898 22.4888 36.7745C22.4888 38.1592 23.6153 39.2857 25 39.2857C26.0899 39.2857 27.0103 38.5838 27.357 37.6116H30.0223C32.33 37.6116 34.2076 35.734 34.2076 33.4263V31.7522V24.9442C34.2076 23.5595 33.0811 22.433 31.6964 22.433Z"
                  fill="white"
                />
                <path
                  d="M35.8104 24.2391C35.8498 24.4694 35.8817 24.7027 35.8817 24.9442V31.7522C35.8817 31.9937 35.8498 32.2269 35.8104 32.4572C36.8192 32.1312 37.5558 31.1941 37.5558 30.0781V26.6183C37.5558 25.5022 36.8192 24.5652 35.8104 24.2391Z"
                  fill="white"
                />
                <path
                  d="M25 10.7143C18.0763 10.7143 12.4442 16.3464 12.4442 23.2701V23.2914C12.9429 22.9139 13.5199 22.6376 14.1566 22.5139C14.5479 16.8667 19.2552 12.3884 25 12.3884C30.7448 12.3884 35.4521 16.8667 35.8434 22.5138C36.48 22.6375 37.057 22.9139 37.5558 23.2914V23.2701C37.5558 16.3464 31.9237 10.7143 25 10.7143Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_48:2">
                  <rect
                    width="28.5714"
                    height="28.5714"
                    fill="white"
                    transform="translate(10.7143 10.7143)"
                  />
                </clipPath>
              </defs>
            </svg>

            <div>
              <h1 className="font-bold text-sm md:text-base lg:text-xl">
              {feature[1].title}

              </h1>
              <p className="text-xs md:text-sm text-gray-600">{feature[1].description}
</p>
            </div>
          </div>
          <div className="flex items-center gap-x-2 lg:gap-x-4">
            <svg
              width="50"
              height="50"
              viewBox="0 0 50 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="25" cy="25" r="25" fill="#CC7829" />
              <g clipPath="url(#clip0_3:111)">
                <path
                  d="M17.4665 10.7143V16.0156C17.4665 20.8888 20.646 25.0318 25 26.5535C29.354 25.0318 32.5335 20.8888 32.5335 16.0156V10.7143H17.4665Z"
                  fill="white"
                />
                <path
                  d="M17.106 21.6416C14.3902 19.3848 12.6453 16.0704 12.4146 12.3884H15.7924V10.7143H10.7143V11.5513C10.7143 13.2542 11.0015 14.924 11.5679 16.5142C12.1153 18.0511 12.9105 19.4799 13.9315 20.7611C15.4426 22.6572 17.4065 24.1535 19.6087 25.1147C18.586 24.103 17.7398 22.928 17.106 21.6416Z"
                  fill="white"
                />
                <path
                  d="M34.2076 10.7143V12.3884H37.5854C37.3547 16.0704 35.6098 19.3848 32.894 21.6416C32.2602 22.928 31.414 24.103 30.3913 25.1147C32.5934 24.1535 34.5574 22.6572 36.0684 20.7611C37.0895 19.4799 37.8847 18.0511 38.4321 16.5142C38.9986 14.924 39.2857 13.2542 39.2857 11.5513V10.7143H34.2076Z"
                  fill="white"
                />
                <path
                  d="M31.4174 37.6116V30.9152H26.6741V27.6819C26.3099 27.8486 25.9359 27.9999 25.5523 28.1339L25 28.3269L24.4477 28.1339C24.0641 27.9999 23.6901 27.8486 23.3259 27.6819V30.9152H18.5826V37.6116H16.9085V39.2857H33.0915V37.6116H31.4174ZM27.5112 35.3795H22.4888V33.7054H27.5112V35.3795Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_3:111">
                  <rect
                    width="28.5714"
                    height="28.5714"
                    fill="white"
                    transform="translate(10.7143 10.7143)"
                  />
                </clipPath>
              </defs>
            </svg>

            <div>
              <h1 className="font-bold text-sm md:text-base lg:text-xl">
              {feature[2].title}

              </h1>
              <p className="text-xs md:text-sm text-gray-600">
              {feature[2].description}

              </p>
            </div>
          </div>
        </section>
      </section>
    </section>
  );
};
const About = () => {
  const [data, dataSet] = useState([
    {
      title: "loading",
      description: "loading",
      link: "loading",
      image: "loading",
    },
  ]);
  const [heading,headingSet]=useState([
    {Title:"loading",
      Heading: "loading",
      Description:"loading"
  }
  ])
  useEffect(() => {
    // fetch(apiUrl+"/api/sliders/")
    //   .then((response) => response.json())
    //   .then((data) => console.log(data));
    async function fetchMyApi() {
      let response = await fetch(apiUrl+"/api/about/", {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      });
      response = await response.json();
      
      dataSet(response);
    }
    fetchMyApi();
    async function fetchMyApi2() {
      let response = await fetch(
        apiUrl+"/api/abouttitle/",
        {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        },
      );
      response = await response.json();
      
      headingSet(response);
    }
    fetchMyApi2();
  }, []);

  return (
    <section id="about" className=" md:h-h80 relative bg-a">
      <section className="relative flex gap-x-8 py-32 gap-y-8 flex-col md:flex-row items-center md:items-start   px-4 md:px-32 h-full w-full">
        <section className="relative h-h40 w-full md:w-1/2">
        <img
          className="absolute top-0 left-0 z-10   md:w-w40 rounded-md h-h40 object-cover"
          src={data[0].image}
          alt=""
        />
        <div  className="absolute top-2 left-2 z-0 bg-pm w-full md:w-w40 rounded-md h-h40 object-cover"></div>
        </section>

        <div className="flex flex-col md:h-h40 relative md:w-1/2 items-start gap-y-6">
        <section className="w-full">
          {/* <h3 className="text-gray-400 text-lg w-full ">{heading[0].Title}</h3> */}

          <h1 className="text-pm font-semibold   w-full  text-4xl md:text-5xl">{heading[0].Title}</h1>

          </section>
          
          <p className=" w-full md:w-3/4">{heading[0].Description}</p>
          <button className="px-6 py-4 rounded-sm bg-pm text-white">
            Get in touch
          </button>
        </div>
      </section>
    </section>
  );
};
const Service = () => {
  const [data, dataSet] = useState([
    {
      Title: "loading",
      Description: "loading",
      FeatureImage: about,
      
    },
  ]);
  const [heading,headingSet]=useState([
    {Title:"loading",
      Heading: "loading",
      Description:"loading"
  }
  ])
  useEffect(()=>{
    async function fetchMyApi() {
      let response = await fetch(apiUrl+"/api/services/", {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      });
      response = await response.json();
      
      dataSet(response);
    }
    fetchMyApi();
    async function fetchMyApi2() {
      let response = await fetch(
        apiUrl+"/api/servicestitle/",
        {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        },
      );
      response = await response.json();
      
      headingSet(response);
    }
    fetchMyApi2();
  },[])
  
  return (
    <section
      id="service"
      className="px-4 relative  md:px-32 py-36 flex flex-col gap-y-6 ">
        <section>
        {/* <h3 className="text-gray-400 text-lg">{heading[0].Title}</h3> */}
      <h1 className="text-pm font-semibold text-4xl md:text-5xl">{heading[0].Title}</h1>
      </section>
      <p className="w-full md:w-1/2 pb-10">{heading[0].Description}</p>
      <div className="flex items-center px-6 gap-x-8 justidy-between">
        <div className="flex flex-wrap gap-x-20 gap-y-14 justify-start items-start md:justify-center md:items-center lg:w-w80 overflow-x-scroll	no-scrollbar">

          {data.map((daata)=>{
            return(
             <div className=" w-full md:w-72 flex-shrink-0 flex flex-col gap-y-2 justify-between rounded-md ">
             {/* <div className="h-p90 flex flex-col gap-y-4"> */}
             <img src={daata.FeatureImage} alt={"Technotiate Solution "+daata.Title} className="w-20 object-cover rounded-tl-md rounded-tr-md h-20"></img>
             <h1 className="text-2xl pt-4 font-semibold text-sd">{daata.Title}</h1>
             <div className="w-20 h-1 bg-pm"></div>
             <p className="leading-tight pt-2 text-gray-500 text-sm">{daata.Description}</p>
             {/* </div> */}
             {/* <h1 className="text-lg text-sd">Read More</h1> */}
   
             </div>
)
          })}
         
          
          
        </div>
        {/* <div className="flex flex-col items-center">
          <svg
            className="h-12 "
            // width="46"
            // height="46"
            viewBox="0 0 46 46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="23" cy="23" r="23" fill="#CC7829" />
            <path
              d="M29 23H17M24 18L29 23L24 18ZM29 23L24 28L29 23Z"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <h3 className="font-semibold text-lg">See more</h3>
        </div> */}
      </div>
    </section>
  );
};
// const WhoWeAre = () => {
//   return (
//     <section id="who" className=" px-4 relative text-center md:px-32 py-24  bg-a">
//      {/* <section className=" flex gap-x-8 py-32 gap-y-8 flex-col md:flex-row items-center  px-4 md:px-32 h-full w-full"> */}
        
//         <div className="flex flex-col items-start gap-y-6">
//           <section className="w-full">
//           <h3 className="text-gray-400 text-lg w-full text-center">Who we are</h3>

//           <h1 className="text-pm font-semibold  text-center w-full  text-4xl md:text-5xl">Welcome to Technotiate</h1>

//           </section>
//           <p className="w-full">
//           Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
//           </p>
        
//         </div>
//       {/* </section> */}
//     </section>
//   );
// };
const Client = () => {
  const [data, dataSet] = useState([1]);
  const [heading, headingSet] = useState([
    { Heading: "loading", description: "loading" },
  ]);
  const handleOnclick=(data)=>{
    window.location.href=data;
  }
  useEffect(() => {
    // fetch(apiUrl+"/api/sliders/")
    //   .then((response) => response.json())
    //   .then((data) => console.log(data));
    async function fetchMyApi() {
      let response = await fetch(apiUrl+"/api/clients/", {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      });
      response = await response.json();
      
      dataSet(response);
    }
    fetchMyApi();
    async function fetchMyApi2() {
      let response = await fetch(
        apiUrl+"/api/clientstitle/",
        {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        },
      );
      response = await response.json();
      
      headingSet(response);
    }
    fetchMyApi2();
  }, []);
  return (
    <section
      id="client"
      className=" flex relative  flex-col  px-4 relative  md:px-32 py-24   relative "
    >
      <img className="h-full opacity-70 absolute object-cover top-0 left-0 z-0 w-full" src={client}/>
      <section className="relative flex flex-col gap-y-12 z-10">
      <section className="flex flex-col items-start gap-y-4">
        {/* <div className="w-16 h-1 bg-sd"></div> */}

      <h1 className="text-pm font-semibold   text-4xl md:text-5xl">
        {heading[0].Title}
      </h1>
      <p className="w-full text-gray-500">{heading[0].Description}</p>
      </section>
      {/* <h1 className="text-pm font-semibold text-4xl md:text-5xl">
        {heading[0].Title}
      </h1> */}
      <div className="flex items-start justify-start content-start w-full  overflow-x-scroll no-scrollbar flex-nowrap gap-x-4 gap-y-2">
        {data.map((data) => {
          return (
            <div className="h-40 w-40 shadow-lg flex-shrink-0 hover:shadow-xl relative bg-gray-300 cursor-pointer" onClick={()=>handleOnclick(data.Link)}>
              <img
                src={data.image}
                className="h-full inset-0 absolute top-0 left-0 w-full object-cover"
                alt=""
              />
                <div className="opacity-0 hover:opacity-100 duration-300 absolute inset-0 z-10 flex justify-center items-center bg-black bg-opacity-50 text-white font-semibold">{data.Title}</div>

            </div>
          );
        })}

        {/* <div className="h-48 w-48  bg-gray-300"></div>
        <div className="h-48 w-48 bg-gray-300"></div>
        <div className="h-48 w-48 bg-gray-300"></div>
        <div className="h-48 w-48 bg-gray-300"></div>
        <div className="h-48 w-48 bg-gray-300"></div> */}
      </div>
      </section>
    </section>
  );
};
const Contact = () => {
  const [data, dataSet] = useState([
    {
      Title: "Contact Us",
      Description: "loading",
      Heading: "loading",
      
      con:{Contact: "loading",
      Contact2:"loading",
      Email:"",},
      Email2:"",
      Location:"",
      
    },
    
  ]);
  const [hour, hourSet] = useState([
    {
      Day: "loading",
      Time: "loading",
      
      
    },
  ]);
  useEffect(()=>{
    async function fetchMyApi() {
      let response = await fetch(apiUrl+"/api/contact/", {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      });
      response = await response.json();
      
      dataSet(response);
    }
    async function fetchMyApi2() {
      let response = await fetch(apiUrl+"/api/contacthour/", {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      });
      response = await response.json();
      
      hourSet(response);
    }
    fetchMyApi();
    fetchMyApi2();
  },[])
  

  return (
    <section
      id="contact"
      className=" relative flex flex-col gap-y-6  px-4 py-24  md:px-32  bg-a"
    >
      {/* <section className="flex items-center gap-x-6"> */}
        {/* <div className="w-16 h-1 bg-sd"></div> */}
<section className="flex flex-col items-center gap-y-6">
      <h1 className="text-pm font-semibold text-center text-4xl md:text-5xl">
        {data[0].Title}
      </h1>
      {/* </section> */}
      <p className="md:w-1/2 text-center">
        {data[0].Description}
      </p>
      </section>
      <section className="flex flex-col pt-8 md:content-center	justify-center md:flex-row gap-y-12">
        <div className=" w-full md:w-2/5 bg-white rounded-md px-6 md:px-12 py-12 flex flex-col gap-y-6 filter pb-18 drop-shadow-md">
          <h1 className="font-bold text-2xl text-pm ">Send us message</h1>
          <form action="#" className="flex flex-col gap-y-4">
            <section className="flex flex-col gap-y-2">
              <label htmlFor="name" className="text-gray-600">
                Name*
              </label>
              <input
                className="w-full py-2 px-2 bg-gray-100 rounded-md"
                name="name"
                value="Enter username"
              ></input>
            </section>
            <section className="flex flex-col gap-y-2">
              <label htmlFor="email" className="text-gray-600">
                Email*
              </label>

              <input
                name="name"
                className="w-full w-full py-2 px-2  bg-gray-100 rounded-md"
                value="Enter Email"
              ></input>
            </section>
            <section className="flex flex-col gap-y-2">
              <label htmlFor="message" className="text-gray-600">
                Message*
              </label>

              <textarea
                rows="7"
                className="w-full w-full py-2 px-2  bg-gray-100 rounded-md"
              >
                Write your message
              </textarea>
            </section>
          </form>
        </div>
        <div className="w-full md:w-2/5 text-lg flex flex-col justify-center   gap-y-6  px-6 md:px-12">
          <section className="flex flex-col gap-y-1">
            <h1 className="font-bold text-2xl">Phone</h1>
            <section className=" pl-2 text-gray-600 fill-current  flex gap-x-2 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
              </svg>
              <p> {data[0].con.Contact}</p>
            </section>
            <section className=" pl-2 text-gray-600 fill-current flex gap-x-2 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
              </svg>
              <p>{data[0].con.Contact2}</p>
            </section>
            
          </section>
          <section className="flex flex-col gap-y-1">
            <h1 className="font-bold text-2xl"> Email</h1>
            <section className="flex text-gray-600 fill-current pl-2 gap-x-2 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
              </svg>
              <p>{data[0].con.Email}</p>
            </section>
            <section className="flex text-gray-600 fill-current pl-2 gap-x-2 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
              </svg>
              <p>{data[0].Email2}</p>
            </section>
          </section>
          <section className="flex flex-col gap-y-1 ">
            <h1 className="font-bold text-2xl"> Address</h1>

            <section className="flex text-gray-600 fill-current pl-2 gap-x-2 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                  clipRule="evenodd"
                />
              </svg>
              <p className="">{data[0].Location}</p>
            </section>
          </section>
          <section className="flex flex-col gap-y-1 ">
            <h1 className="font-bold text-2xl"> Business Hours</h1>

            {hour.map((data)=>{
              return(
              <section className="flex text-gray-600 fill-current pl-2 gap-x-2 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <section className="flex pl-2 gap-x-8">
                <p>{data.Day}</p>
                <p>{data.Time}</p>
              </section>
            </section>
)
            })}
            
            
          </section>
        </div>
      </section>
    </section>
  );
};
// const Subscribe = () => {
//   return (
//     <section className=" relative flex flex-col gap-y-6  px-4 py-24  md:px-32  bg-white">
//       <h1 className="text-pm font-semibold text-center text-4xl md:text-5xl">
//         Subscribe
//       </h1>
//       <p className="text-center text-gray-600 text-lg">
//         Subscribe our newsletter to stay updated every moment
//       </p>
//       <form action="" className="flex justify-center" method="post">
//         <input
//           className="w-full md:w-1/4 py-4 bg-gray-100 px-4"
//           type="text"
//           name="email"
//           id="email"
//           value="Enter Email"
//         />
//         <input
//           type="submit"
//           value="Submit"
//           className="py-2 bg-pm text-white px-6"
//         />
//       </form>
//     </section>
//   );
// };
export default Landing;
