import { Link } from "react-router-dom";

const ResetPwd=()=>{
    return(
        <section className="w-w100 h-h100 px-6 md:px-0 flex justify-center items-center bg-gradient-to-tr from-pmdark  to-sdgrad">
        
            <section className="md:h-p90 md:w-p60 w-full bg-white   rounded-lg drop-shadow-lg	 ">
        
        <section className=" w-full   flex flex-col box-border	 justify-between   px-6 md:px-6 gap-y-8 md:gap-y-6 py-16">
            <section className="flex flex-col gap-y-4">
            <h1 className="text-center  font-bold font-roboto text-2xl">Reset Password</h1>
            <p className="text-gray-400 text-center ">Please check your email for a message with your code. Your code is 6 numbers long.
</p>
            </section>
            <section className="w-full flex flex-col py-6">
            <form className="flex flex-col pb-4 items-center gap-y-4  w-full md:px-14">
                <section className="flex flex-col gap-y-4 w-full">
                <input className="py-3 px-4 bg-gray-200 rounded-3xl w-full " type="text" placeholder="6 digit code"></input>
                <input className="py-3 px-4 bg-gray-200 rounded-3xl w-full " type="password" placeholder="New Password"></input>
                <input className="py-3 px-4 bg-gray-200 rounded-3xl w-full " type="password" placeholder="Conform Password"></input>
                </section>
                <input type="submit" value="SUBMIT" className="py-3 w-full px-14 text-white bg-pm rounded-3xl"></input>
            </form>
            <section className="flex justify-center gap-x-2">
                <p className="text-gray-500 pb-2 text-sm underline-offset-1	 text-center">Back to </p>
            <Link to="/helpdesk">
        
        <p className="text-gray-600 pb-2 text-sm font-semibold underline-offset-1	 text-center">Login.</p>
        
        </Link>
            </section>
            
            </section>
            
            <Link to="/">
            <p className="text-gray-400 pb-2 underline-offset-1	 text-center">Go to homepage</p>
        
            </Link>
        </section>
            </section>
        </section>
        
        )
}
export default ResetPwd;