import Nav from "./header.js";

import Landing from "./landing";
import Footer from "./footer";
const Home=()=>{
return (
    <section>
    <Nav />
  
  <Landing />
  <Footer />
  {/* <Menu /> */}
</section>
)

}
export default Home;