import React, { useState,useEffect } from "react";
import apiUrl from "../config"
import { Link } from "react-router-dom";
import logo from "../static/image/logo.png";
const Nav = () => {
  //   const [scrollStat, updateScroll] = useState(false);
  //   const scroll = () => {
  //     updateScroll = true;
  //   };
  
  // window.addEventListener("scroll", scroll);
  return (
    <section className="  w-full">
      <TopNav /> 
      <ButtomNav />
      {/* <NavPopup active={active} handleOnclick={handleOnclick()}/> */}
    </section>
  );
};
const TopNav = () => {
  const [data, dataSet] = useState([
    {
      Contact: "loading",
      Contact2: "loading",
      Email: "loading",
      
    },
  ]);
  useEffect(()=>{
    async function fetchMyApi() {
      let response = await fetch(apiUrl+"/api/contactnum/", {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      });
      response = await response.json();
      
      dataSet(response);
    }
    fetchMyApi();
  },[])
  return (
    <section id="topnav" className="flex justify-end md:justify-between px-4 md:px-32 text-ss py-1.5  bg-gray-100 bg-opacity-1">
      <section className="hidden md:flex gap-x-8 text-ss">
        <div className="flex gap-x-2 items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
          </svg>
          <p>{data[0].Contact} / {data[0].Contact2}</p>
        </div>
        <div className="flex gap-x-2 items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M2.94 6.412A2 2 0 002 8.108V16a2 2 0 002 2h12a2 2 0 002-2V8.108a2 2 0 00-.94-1.696l-6-3.75a2 2 0 00-2.12 0l-6 3.75zm2.615 2.423a1 1 0 10-1.11 1.664l5 3.333a1 1 0 001.11 0l5-3.333a1 1 0 00-1.11-1.664L10 11.798 5.555 8.835z"
              clipRule="evenodd"
            />
          </svg>
          <a href="mailto: xyz@example.com">{data[0].Email}</a>
        </div>
      </section>
      <section className="flex ">
        <div className="flex gap-x-2 items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-2 0c0 .993-.241 1.929-.668 2.754l-1.524-1.525a3.997 3.997 0 00.078-2.183l1.562-1.  562C15.802 8.249 16 9.1 16 10zm-5.165 3.913l1.58 1.58A5.98 5.98 0 0110 16a5.976 5.976 0 01-2.516-.552l1.562-1.562a4.006 4.006 0 001.789.027zm-4.677-2.796a4.002 4.002 0 01-.041-2.08l-.08.08-1.53-1.533A5.98 5.98 0 004 10c0 .954.223 1.856.619 2.657l1.54-1.54zm1.088-6.45A5.974 5.974 0 0110 4c.954 0 1.856.223 2.657.619l-1.54 1.54a4.002 4.002 0 00-2.346.033L7.246 4.668zM12 10a2 2 0 11-4 0 2 2 0 014 0z"
              clipRule="evenodd"
            />
          </svg>
          <Link to="/helpdesk">
          <p>Support Portal</p>
          </Link>
        </div>
      </section>
    </section>
  );
};

const ButtomNav = (props) => {
  const [active,setStat]=useState(false)
  
  const handleOnclick=()=>{
    console.log("testt")
    if (active===false){
      setStat(true);
    }
    else if (active===true){
      setStat(false)
    }
   
 }
  // const active=props.active;
  // const handleOnclick=props.handleOnclick();
  return (
    <section  className="">
<section id="navpop" className=
      {active===true?"flex w-full z-50 flex-col fixed h-screen transition-all  duration-700 top-0 left-0  bg-gray-300":"hidden w-full transition-all  duration-700 h-screen  z-50 fixed top-0 left-0 bg-black"}>
      
      <section className="flex py-6 px-6 justify-end w-full">
      
      <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-gray-600 fill-current" onClick={()=>handleOnclick()} fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
      </svg>
      </section>
      <div className=" flex flex-col items-center justify-center w-full text-sd gap-y-6">
              <a href="#slider" onClick={()=>handleOnclick()} className=" hover:text-sddark">
                HOME
              </a>
              <a href="#about" onClick={()=>handleOnclick()} className=" hover:text-sddark">
                ABOUT
              </a>
              <a href="#service" onClick={()=>handleOnclick()} className=" hover:text-sddark">
                SERVICE
              </a>
              
              <a href="#client" onClick={()=>handleOnclick()} className=" hover:text-sddark">
                CLIENTS
              </a>
              <a href="#contact" onClick={()=>handleOnclick()} className=" hover:text-sddark">
                CONTACT
              </a>
            </div>
      </section>
<section id="buttomnav"  className="  transition-all px-4  py-4 drop-shadow-lg duration-700 z-40 left-0 top-0 w-full  md:px-32 bg-white flex  md:py-4 items-center justify-between">
      <div >
        <a href="/">
          <img src={logo} id="navlogo" className="w-60" alt="" />
          <h1 className="hidden"> Technotiate</h1>
        </a>
      </div>
      <div className=" hidden md:flex text-sd gap-x-12">
        <a href="#slider" className=" border-b-2 border-transparent hover:border-pm transition-all duration-700 py-2 hover:text-sddark">
          HOME
        </a>
        <a href="#about" className="border-b-2 border-transparent hover:border-pm transition-all duration-700 py-2  hover:text-sddark">
          ABOUT
        </a>
        <a href="#service" className="border-b-2 border-transparent hover:border-pm transition-all duration-700 py-2  hover:text-sddark">
          SERVICE
        </a>
        
        <a href="#client" className="border-b-2 border-transparent hover:border-pm transition-all duration-700 py-2  hover:text-sddark">
          CLIENTS
        </a>
        <a href="#contact" className="border-b-2 border-transparent hover:border-pm transition-all duration-700 py-2  hover:text-sddark">
          CONTACT
        </a>
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8  md:hidden" onClick={()=>handleOnclick()} fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
</svg>
</section>
    </section>
  );
};
export default Nav;
