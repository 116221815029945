const Footer = () => {
  return (
    <section className="bg-pm px-4 flex flex-col items-center justify-center gap-x-20 md:px-32 py-14">
      <div className="flex flex-wrap text-gray-200 gap-x-8">
        <a href="/" className=" hover:text-white">
          HOME
        </a>
        <a href="/" className=" hover:text-white">
          ABOUT
        </a>
        <a href="/" className=" hover:text-white">
          SERVICE
        </a>
        <a href="/" className=" hover:text-white">
          WHO WE ARE
        </a>
        <a href="/" className=" hover:text-white">
          CLIENTS
        </a>
        <a href="/" className=" hover:text-white">
          CONTACT
        </a>
      </div>
      <div>
        <p className="text-gray-700">
          © Copyright 2021 Technotiate Solution Pvt. Ltd. All right reserved
        </p>
      </div>
    </section>
  );
};

export default Footer;
